import React from "react"
import { StaticQuery, graphql , Link } from "gatsby"
import SecHeader from "../components/secondHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/webLogo.png"
import foodgath from '../images/FG@2x.png'
import washtenaw_lit from '../images/Washtenawliteracy@2x.png'
import fli from '../images/FLI@2x.png'
import homepage from '../images/20180724_132201.jpg'
import Img from 'gatsby-image'

const getInvolvedPage = () => (



<StaticQuery
    query={graphql`
       query volunteerQuery{
        allWordpressWpVolunteer{
    edges {
      node {
        title
        content
        acf{
          contact_info
        }
        featured_media {
             localFile{
                            childImageSharp{
                                resolutions(width:900, height: 500){
                                    src
                                    width
                                    height
                                }
                            }

                            
                        }

              alt_text
            } 
        
      }
        
    }
  }
    }


    
    `}


 render={data=>(

  <Layout>
  	<SecHeader pageTitle="Volunteer" image={homepage}/>
    <SEO title="Page two" />
{data.allWordpressWpVolunteer.edges.map(({node}) => (
    <div class="uk-section uk-section-default uk-container" key={node.slug} >
    <div class="uk-container">

        

        <div class=" uk-flex flexdirection" uk-grid>
            <div class="uk-width-1-2@m uk-text-small">
                <h2> {node.title}</h2>
                <p style={{color: '#2D4B65' }} dangerouslySetInnerHTML={{__html: node.content}}/>
                  
            </div>
            <div  class="uk-width-1-3@m uk-align-right@m volMar">
            <img class="uk-width-large uk-margin-bottom" src={node.featured_media.localFile.childImageSharp.resolutions.src} alt={node.featured_media.alt_text} />

                <div class="programCon uk-padding-small uk-border-rounded">
                <h3 style={{color: "#E5901A"}}>Contact Info:</h3>
          <p> {node.acf.contact_info}
        </p>
                </div>
            </div>



            
        </div>

    </div>
  </div> 




))}

 
  </Layout>
)}

/>
)

export default getInvolvedPage